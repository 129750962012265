export default class ValidateImei {

    constructor(imei) {
        this.imei = parseInt(imei);
    }

    /**
     * Check if the IMEI set is valid
     * @returns {boolean}
     */
    isValid() {

        if (!this.imei || !this.isFifteenDigits() || !this.isNumerical()) {
            return false;
        }

        let digits = this.imeiAsDigits();

        let imeiLast = digits.pop();

        let log = [];

        digits.forEach((digit, key) => {

            if (this.isOdd(key) && key > 0) {
                // Get double digits
                let double = (digit * 2).toString().split("");

                // Sum double digits
                digit = double.reduce(function(a, b){
                    return parseInt(a) + parseInt(b);
                }, 0);
            }

            // Append log
            log.push(digit);
        });

        // Sum log & multiply by 9
        let sum = log.reduce(function(a, b){
            return parseInt(a) + parseInt(b);
        }, 0) * 9;

        // Get the last digit of the sum above
        let lastDigitOfSum = sum.toString().split("").pop();

        // Compare the last digit with imeiLast
         if (lastDigitOfSum.toString() !== imeiLast.toString()) {
             return false;
         }

         return true;
    }

    /**
     * Check the IMEI is numerical
     * @returns {boolean}
     */
    isNumerical() {
        if (typeof this.imei === "number") {
            return true;
        }
        return false;
    }

    /**
     * Check the imei is 15 characters in length
     * @returns {boolean}
     */
    isFifteenDigits() {
        if (this.imei.toString().length !== 15) {
            return false;
        }

        return true;
    }

    /**
     * Get the imei as digits
     * @returns {number[]}
     */
    imeiAsDigits() {
        let digits = this.imei.toString().split('');
        return digits.map(Number);
    }

    /**
     * Check if a number is odd
     * @param number
     * @returns {number}
     */
    isOdd(number) {
        return number % 2;
    }
}