<template>
    <div>
        <!-- Barcode scanner -->
        <scanner
            @barcodeHasBeenScanned="barcodeScanned($event)"
        ></scanner>


        <div class="scan-page" v-if="device">

            <!-- Initial Page -->
            <template v-if="!imeiEntered">

                <!-- Manual entry -->
                <div class="container repair-container">
                    <div class="row">
                        <div class="col-12">
                            <label>Manually enter the IMEI</label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" inputmode="numeric" pattern="[0-9]*" v-model="imei" v-on:keyup.enter="validateImei()">
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-success" @click="validateImei()">Add</button>
                        </div>
                    </div>
                </div>

                <!-- Validating overlay -->
                <loading-state
                        v-if="validating"
                        overlay
                        title="Validating IMEI">
                </loading-state>
            </template>

            <!-- Valid IMEI -->
            <template v-if="imeiValid && imeiEntered">
                <div class="container repair-container">
                    <empty-state
                            statusIcon="success"
                            :title="`The IMEI <strong>${imei}</strong> is valid for this device`">
                    </empty-state>
                    <div class="row">
                        <div class="col-md-6 offset-md-3">
                            <button class="btn btn-block btn-info" @click="clearImei()">Re-enter IMEI</button>
                        </div>
                    </div>
                </div>
            </template>

            <!-- Invalid IMEI -->
            <template v-if="!imeiValid && imeiEntered">
                <div class="container repair-container">
                    <empty-state
                            statusIcon="error"
                            :title="`The IMEI <strong>${imei}</strong> is not valid for this device`"
                            subtitle="Click the button below to re-enter the IMEI or contact your SSM if you require more help">
                    </empty-state>
                    <div class="row">
                        <!--                    <div class="col-6">-->
                        <!--                        <button class="btn btn-block btn-danger btn-sm">IMEI mismatch</button>-->
                        <!--                    </div>-->
                        <div class="col-md-6 offset-md-3">
                            <button class="btn btn-block btn-info" @click="clearImei()">Re-enter IMEI</button>
                        </div>
                    </div>
                </div>
            </template>

            <!-- Footer -->
            <fixed-footer
                    :class="{'active': footerIsActive}"
                    :nextLabel="this.nextRepairStep($route.name).title"
                    :nextRoute="this.nextRepairStep($route.name).routeName"
                    :prevRoute="this.prevRepairStep($route.name).routeName">
            </fixed-footer>

            <!-- Adding IMEI Modal -->
            <adding-imei-modal
                    ref="addingImeiModal"
            ></adding-imei-modal>

            <!-- Repeat Repair Modal -->
            <repeat-repair-modal
                ref="repeatRepairModal"
            ></repeat-repair-modal>
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    import Validator from '../../services/validateImei';

    export default {

        props:["jobId", "deviceId"],

        data() {
            return {
                imei:null,
                imeiEntered: false,
                imeiValid: false,
                validating: false,
                footerActive: false
            }
        },

        watch: {
            device(newDevice) {
                if (newDevice && newDevice.imei_complete && this.imei) {
                    this.validating = false;

                    if (newDevice.imei_error) {
                        this.handleImeiErrors(JSON.parse(newDevice.imei_errors));
                    } else {
                        if (newDevice.repeat_repair_order) {
                            this.$refs.repeatRepairModal.displayModal();
                        }

                        this.imeiEntered = true;
                        this.footerActive = true;
                    }
                }
            },

            jobId(jobId) {
                if (jobId) {
                    this.loadJob({
                        jobId: this.jobId
                    });
                }
            }
        },

        mounted() {
            this.loadDeviceForJob({
                jobId: this.jobId,
                deviceId: this.deviceId,
            }).then(() => {
                if (this.device.imei_error) {
                    this.handleImeiErrors(JSON.parse(this.device.imei_errors));

                } else if (this.device.repeat_repair_order) {
                    this.$refs.repeatRepairModal.displayModal();
                }

                this.setCurrentStep({
                    jobId: this.jobId,
                    deviceId: this.deviceId,
                    currentStep: this.$route.name,
                });
            });
        },

        computed: {
            ...mapGetters([
                "appRegion",
                "device",
                "deviceImei",
                "isJobManager",
                "nextRepairStep",
                "prevRepairStep",
                "user"
            ]),

            /** Check if footer should be active */
            footerIsActive() {
                if (this.footerActive || this.device.imei_scanned || this.isJobManager) {
                    return true;
                }

                return false;
            }
        },

        methods: {
            ...mapActions([
                "clearImeiScanned",
                "displayToast",
                "loadDeviceForJob",
                "saveImei",
                "setCurrentStep",
                "setDeviceImei"
            ]),

            /** Barcode scan */
            barcodeScanned(barcode) {
                this.imei = barcode.data;
                this.validateImei();
            },

            /** Clear IMEI */
            clearImei() {
                this.imei = null;
                this.imeiEntered = false;
                this.imeiValid = false;
                this.clearImeiScanned({
                    jobId: this.jobId,
                    deviceId: this.deviceId
                })
            },

            /** Validate IMEI */
            validateImei() {
                if (this.validating) return;

                this.validating = true;

                let validator = new Validator(this.imei);
                let imeiValid = validator.isValid();

                if (!imeiValid) {
                    this.imeiValid = false;
                    this.validating = false;
                    this.imeiEntered = true;
                    return;
                }

                this.imeiValid = true;

                let technicianId = null;
                if (this.user && this.user.technician && this.user.technician.id) {
                    technicianId = this.user.technician.id;
                }

                this.saveImei({
                    jobId: this.jobId,
                    deviceId: this.deviceId,
                    imei: this.imei,
                    technicianId: technicianId,
                    logImeiScan: true
                }).catch((error) => {
                    this.handleImeiErrors(error);
                })
            },

            handleImeiErrors(error) {
                this.$refs.addingImeiModal.displayError(error);
                this.validating = false;

                if (error.code === 'NON_EU_DEVICE' || error.code === 'IW_DEVICE_OOW') {
                    this.footerActive = true;
                    this.imeiEntered = true;

                } else {
                    this.footerActive = false;
                    this.imeiEntered = false;
                }
            }
        }
    }
</script>

<style scoped>

</style>